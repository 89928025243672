import { StoreContext } from '../store';
import {
	Container,
	StackDivider,
	Text,
	VStack,
	useColorMode,
	useColorModeValue
} from '@chakra-ui/react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useRef, useEffect } from 'react';
import Chatbox from './ChatBox';
import Message from './Message';
import { useTranslate } from '@tolgee/react';

export default function MessageContainer() {
	const { t } = useTranslate();
	const {
		state: {
			status: { page }
		}
	} = useContext(StoreContext);
	const messages = page.content.right_upper_panel.message_list;
    //get last message message might be empty
    let lastMessage = messages[messages.length - 1];
	const color = useColorModeValue('gray.300', 'gray.700');
    const startOfMessagesRef = useRef<HTMLDivElement>(null);
    const endOfMessagesRef = useRef<HTMLDivElement>(null);

    // useEffect(() => {
    //     // Scroll to the bottom of the container every time children change
    //     if (endOfMessagesRef.current){
    //         endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
    //     }
    //     lastMessage = messages[messages.length - 1];
    //   }, [messages]);


    useEffect(() => {
         // Scroll to the bottom of the container every time children change
         if (endOfMessagesRef.current){
             endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
         }
       }, [lastMessage]);


	return (
		<>
            <Container
                className="messages__box"
                maxW="100em"
                overflowY="auto"
                h="90vh"
                css={{
                    '&::-webkit-scrollbar': {
                        width: '4px'
                    },
                    '&::-webkit-scrollbar-track': {
                        width: '6px'
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#e2e8f066',
                        borderRadius: '24px'
                    }
                }}
            >
                <div ref={startOfMessagesRef} id='start_of_message_ref'/>

                <VStack divider={<StackDivider borderColor={color} />} spacing={8}>
                    {messages.map((el, idx) => (
                        <Message key={idx} id={idx} message={el} />
                    ))}
                </VStack>

                <div ref={endOfMessagesRef} id='end_of_message_ref'/>
            </Container>
		</>
	);
}
