import { StoreContext } from '../../store';
import {
	Button,
	Divider,
	FormControl,
	FormLabel,
	HStack,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Popover,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Portal,
	Spinner,
	Text,
	VStack,
	useDisclosure,
} from '@chakra-ui/react';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, useContext, useRef, useState } from 'react';
import { Element } from '@/types/dynamic-app';
import PopoverSubContent from './PopoverSubContent';
import DynamicContent from '../sidebar/DynamicContent';
import { useTranslate } from '@tolgee/react';

export default function Search() {
	const { t } = useTranslate();
	const inputRef = useRef<HTMLInputElement | null>(null);
	const { isOpen, onToggle, onClose } = useDisclosure();
	const {
		isOpen: isModalOpen,
		onOpen: onModalOpen,
		onClose: onModalClose,
	} = useDisclosure();

	const {
		state: { status },
		callAction,
		actionLoading,
		getActionLoading,
	} = useContext(StoreContext);
	const { page } = status;
	const [searchTerm, setSearchTerm] = useState('');

	// Retrieve the 'hidden' property
	const isHidden = page.content.left_panel.search_bar.hidden;

	const search = async () => {
		page.content.left_panel.search_bar.inner_text = searchTerm;
		await callAction('search', false, status);
		if (inputRef.current) inputRef.current.value = '';
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value);
	};

	return (
		<VStack w="100%" spacing={0} justifyItems="center" paddingBottom={8}>
			<VStack
				width="100%"
				position="relative"
				alignItems="start"
				justifyItems="center"
				spacing={4}
			>
				{/* Render filters if advanced_search exists and advanced_button is not present */}
				{page.content.left_panel.search_bar.advanced_search &&
					!page.content.left_panel.search_bar.advanced_button && (
						<DynamicContent
							columns={2}
							form={page.content.left_panel.search_bar.advanced_search}
						/>
					)}

				<VStack w="100%" gap={0}>
					<FormControl>
						<HStack justifyContent="space-between" justifyItems="center">
							<FormLabel>
								{page.content.left_panel.search_results &&
									page.content.left_panel.search_results.title &&
									t(page.content.left_panel.search_results.title)}
							</FormLabel>

							{/* Render Advanced Button if applicable */}
							{page.content.left_panel.search_bar.advanced_search &&
								page.content.left_panel.search_bar.advanced_button && (
									<Button
										size="xs"
										onClick={() => {
											if (inputRef.current) inputRef.current.value = '';
											onClose();
											onModalOpen();
										}}
									>
										{t('input.advanced')}
									</Button>
								)}
						</HStack>
					</FormControl>

					<Popover
						isOpen={isOpen}
						onClose={onClose}
						placement="right-start"
						closeOnBlur={false}
						// Increase the width and height here
						// You can adjust these values as per your design requirements
						// Example: width="500px", maxHeight="400px"
						// Additionally, make it responsive if needed
					>
						<PopoverTrigger>
							{/* Conditional Rendering based on 'hidden' */}
							{isHidden ? (
								// When hidden is true, render only the Search Button with full width
								<Button
									w="100%"
									leftIcon={
										<FontAwesomeIcon
											icon={page.content.left_panel.search_bar.emoticon as IconName}
											color="#CBD5E0"
										/>
									}
									onClick={() => {
										search().then(() => {
											if (!isOpen) onToggle();
										});
									}}
									isLoading={actionLoading.has('search')}
								>
									{t('app.search_filter.search_client')}
								</Button>
							) : (
								// When hidden is false, render the traditional InputGroup
								<InputGroup>
									<InputLeftElement pointerEvents="none">
										<FontAwesomeIcon
											icon={page.content.left_panel.search_bar.emoticon as IconName}
											color="#CBD5E0"
										/>
									</InputLeftElement>
									{!page.content.left_panel.search_bar.button &&
										actionLoading.has('search') &&
										!isOpen && (
											<InputRightElement pointerEvents="none">
												<Spinner size="sm" />
											</InputRightElement>
										)}
									<Input
										type="text"
										placeholder={
											page.content.left_panel.search_bar.placeholder &&
											t(page.content.left_panel.search_bar.placeholder)
										}
										onChange={handleChange}
										onKeyUp={(e) => {
											if (e.key === 'Enter') {
												if (searchTerm.length > 0) {
													search().then(() => {
														if (!isOpen) onToggle();
													});
												}
											}
										}}
										ref={inputRef}
									/>
									{page.content.left_panel.search_bar.button && (
										<InputRightElement width="5rem">
											<Button
												h="1.75rem"
												size="sm"
												onClick={() => {
													search().then(() => {
														if (!isOpen) onToggle();
													});
												}}
												isLoading={actionLoading.has('search')}
											>
												Search
											</Button>
										</InputRightElement>
									)}
								</InputGroup>
							)}
						</PopoverTrigger>
						<Portal>
							<PopoverContent
								width={["90vw", "500px", "600px"]} // Responsive widths: 90vw on small screens, 500px medium, 600px large
								maxHeight="500px" // Set max height if needed
								overflowY="auto" // Enable scrolling if content overflows
								// Additional styling if needed
							>
								<PopoverHeader fontWeight="semibold" fontSize="lg">
									{t((page.content.left_panel.search_results as Element).title as string)}
								</PopoverHeader>
								<PopoverCloseButton onClick={onClose} />
								<PopoverBody>
									<PopoverSubContent callback={onClose} />
								</PopoverBody>
							</PopoverContent>
						</Portal>
					</Popover>
				</VStack>

				{/* Modal for Advanced Search */}
				<Modal
					isOpen={isModalOpen}
					onClose={() => {
						onModalClose();
						page.content.left_panel.search_results.results = [];
					}}
					size="3xl" // Increased size from "2xl" to "3xl"
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader fontSize="lg">{t('input.advanced.title')}</ModalHeader>
						<ModalCloseButton />
						<ModalBody paddingBottom={4}>
							<VStack spacing={4}>
								<VStack alignItems="end" w="100%">
									{/* Advanced Search Filters */}
									{page.content.left_panel.search_bar.advanced_search && (
										<DynamicContent
											columns={2}
											form={page.content.left_panel.search_bar.advanced_search}
										/>
									)}
									<Button onClick={search} isLoading={actionLoading.has('search')}>
										Search
									</Button>
								</VStack>
								{page.content.left_panel.search_results.results &&
									page.content.left_panel.search_results.results.length > 0 && (
										<>
											<Divider />
											<PopoverSubContent callback={onModalClose} />
										</>
									)}
							</VStack>
						</ModalBody>
					</ModalContent>
				</Modal>
			</VStack>
		</VStack>
	);
}
