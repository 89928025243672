// src/providers/MaybeAuthProvider.tsx

import React, { ReactNode, createContext, useContext } from "react";
import {
	AuthProvider as OidcAuthProvider,
	useAuth as useOidcAuthOriginal,
	AuthContext as OidcAuthContext,
	AuthContextProps
} from "react-oidc-context";

// Define the shape of the auth context for the dummy context
interface AuthContextType {
	isAuthenticated: boolean;
	isLoading: boolean;
	user: any;
	signinRedirect: () => void;
	signoutRedirect: () => void;
}

// Create a dummy context when auth is disabled
const DummyAuthContext: AuthContextType = {
	isAuthenticated: true,
	isLoading: false,
	user: null,
	signinRedirect: () => {},
	signoutRedirect: () => {},
};

// Auth context fallback
const DefaultAuthContext = createContext<AuthContextType>(DummyAuthContext);

// Custom useAuth hook
export const useAuth = (): AuthContextProps | AuthContextType => {
	const authEnabled = process.env.REACT_APP_AUTH === "1";

	// Unconditionally call both useContext hooks
	const oidcAuthContext = useContext(OidcAuthContext);
	const defaultAuthContext = useContext(DefaultAuthContext);

	if (authEnabled) {
		if (!oidcAuthContext) {
			throw new Error("useAuth must be used within an OIDC auth context");
		}
		return oidcAuthContext;
	}

	return defaultAuthContext;
};

// The MaybeAuthProvider component
interface MaybeAuthProviderProps {
	children: ReactNode;
}

const MaybeAuthProvider: React.FC<MaybeAuthProviderProps> = ({ children }) => {
	const authEnabled = process.env.REACT_APP_AUTH === "1";

	const oidcConfig = {
		authority: `${process.env.REACT_APP_AUTH_HOST}/realms/${process.env.REACT_APP_AUTH_REALM}`,
		client_id: process.env.REACT_APP_AUTH_CLIENT_ID!,
		client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET!,
		redirect_uri: `${window.location.origin}/auth/login/callback`,
		response_type: "code",
		scope: "openid profile email",
		automaticSilentRenew: true,
	};

	if (authEnabled) {
		return <OidcAuthProvider {...oidcConfig}>{children}</OidcAuthProvider>;
	} else {
		return (
			<DefaultAuthContext.Provider value={DummyAuthContext}>
				{children}
			</DefaultAuthContext.Provider>
		);
	}
};

export default MaybeAuthProvider;
